import Login from "./Pages/Login";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Gameplay from "./Pages/Gameplay";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./Redux";
import { Provider, useSelector } from "react-redux";
import BonusGame from "./Pages/BonusGame";
import HowItWorks from "./Pages/HowItWorks";
import Winners from "./Pages/Winners";
import Plans from "./Pages/Plans";
import LeaderBoard from "./Pages/Leaderboard";
import StorePage from "./Pages/Store";
import "./i18";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const userMobile = useSelector((state) => state.UserReducer.mobile);

  if (!userMobile) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/game-play" element={<Gameplay />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/bonus-game" element={<BonusGame />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/leader-board" element={<LeaderBoard />} />
        <Route path="/store" element={<StorePage />} />
      </Routes>
    </Router>
  );
}

export default App;
