const getUser = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  if (user) return user;

  return {
    mobile: null,
    points: 0,
    unlockedGames: 0,
  };
};

const initialState = getUser();

const UserReducer = (state = initialState, action) => {
  if (action.type === "ADD_USER") {
    const newState = action.payload;

    localStorage.setItem("user", JSON.stringify(newState));

    return newState;
  } else if (action.type === "DELETE_USER") {
    const newState = {
      mobile: null,
      points: 0,
      unlockedGames: 0,
    };

    localStorage.removeItem("user");

    return newState;
  } else if (action.type === "UPDATE_POINTS") {
    const newState = {
      ...state,
      points: Number(state.points) + Number(action.payload),
    };

    localStorage.setItem("user", JSON.stringify(newState));

    return newState;
  }
 else if (action.type === "UPDATE_UNLOCKEDGAMES") {
  const newState = {
    ...state,
    unlockedGames: Number(action.payload),
    playedGames: Number(state.playedGames)+1,
  };
  console.log(action.payload);
  localStorage.setItem("user", JSON.stringify(newState));

  return newState;
}
  return state;
};

export default UserReducer;
