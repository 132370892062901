import React from "react";
import tw from "tailwind-styled-components";
import Modal from "react-modal";
import Images from "../Images";
import SingleDuration from "./SingleDuration";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


const UnsubscribeModal = ({
  isModalOpen,
  closeModal,
  logout,
  unsubscribeLoading,
  
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          height: "auto",
          inset: "auto",
          width: "90%",
          margin: "0 5%",
          maxWidth: 420,
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      <Wrapper>
        {/* <img src={Images.Emoji} alt="" className="w-36 md:w-44 mx-auto mb-5" /> */}
        <Title>{t("you_want_unsubscrib")}</Title>

        <div className="flex items-center justify-center space-x-6">
          <Btn className="bg-gray-200 text-gray-700" onClick={logout}>
            {!unsubscribeLoading && t("yes")}
            {unsubscribeLoading && (
              <ReactLoading
                type={"spin"}
                color={"#020f2a"}
                height={30}
                width={30}
              />
            )}
          </Btn>
          <Btn onClick={closeModal}>{t("no")}</Btn>
        </div>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = tw.div` py-6 flex flex-col items-center justify-between`;
const Title = tw.h2`text-xl text-center font-bold text-gray-700 mb-4  -mt-4`;
const PrimaryBtn = tw.button`text-lg px-4 py-2 rounded-full font-medium text-white bg-primary-color block mt-8 w-full`;
const SecondaryBtn = tw.button` block text-gray-500 mt-3`;
const TimeDuration = tw.div` flex items-center justify-center `;

const Btn = tw.button`w-full bg-primary-color shadow-lg rounded-full h-12 w-28 overflow-hidden flex justify-center items-center   font-medium  text-white mt-8 mb-auto `;

export default UnsubscribeModal;
