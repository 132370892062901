import React from "react";
import tw from "tailwind-styled-components";
import Modal from "react-modal";
import Images from "../Images";
import SingleDuration from "./SingleDuration";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PointsModal = ({
  isModalOpen,
  closeModal,
  resultArr,
  goToBonusGame,
  totalPoints,
}) => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.UserReducer);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          height: "auto",
          inset: "auto",
          width: "90%",
          margin: "0 5%",
          maxWidth: 420,
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      <Wrapper>
        <img src={Images.Emoji} alt="" className="w-36 md:w-44 mx-auto mb-5" />
        <Title>
          {t("b_my_friend")}
          <br /> {t("y_got_point")}
        </Title>

        <p className="text-center text-sm font-medium text-gray-500">
          {t("total_earned")}
        </p>
        <p className="text-xl text-green-500 text-center font-bold mb-4">
          {totalPoints}
        </p>

        <p className="text-gray-500 text-center font-medium text-sm mb-2">
          {t("your_points")}
        </p>

        <TimeDuration>
          <SingleDuration light={true} result={resultArr?.[0]} />
          <SingleDuration light={true} result={resultArr?.[1]} />
          <SingleDuration light={true} result={resultArr?.[2]} />
          <SingleDuration light={true} result={resultArr?.[3]} />
        </TimeDuration>

        {Number(user.points) >= 200 && (
          <Btn onClick={goToBonusGame}>{t("p_b_g")}</Btn>
        )}
        {Number(user.points) < 200 && <Btn onClick={goToHome}>{t("Continue")}</Btn>}

        {Number(user.points) >= 200 && (
          <SecondaryBtn onClick={goToHome}>{t("Continue")}</SecondaryBtn>
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = tw.div` py-4 flex flex-col items-center justify-between`;
const Title = tw.h2`text-2xl text-center font-bold text-gray-700 mb-8  -mt-4`;
const PrimaryBtn = tw.button`text-lg px-4 py-2 rounded-full font-medium text-white bg-primary-color block mt-8 w-full`;
const SecondaryBtn = tw.button` block text-gray-500 mt-3`;
const TimeDuration = tw.div` flex items-center justify-center `;

const Btn = tw.button`w-full bg-primary-color shadow-lg rounded-full h-12 overflow-hidden flex justify-center items-center   font-medium  text-white mt-8 mb-auto `;

export default PointsModal;
