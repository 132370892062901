import React from "react";
import tw from "tailwind-styled-components";
import Modal from "react-modal";
import Images from "../Images";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ZeroModal = ({ isModalOpen, closeModal }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          height: "auto",
          inset: "auto",
          width: "90%",
          margin: "0 5%",
          maxWidth: 420,
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <Wrapper>
        <Title>{t("Zero")}</Title>

        <img
          src={Images.ZeroBal}
          alt=""
          className="w-36 md:w-44 h-40 mx-auto my-5"
        />

        <p className="text-gray-500 text-center">
        {t("ToContinue")}
        </p>

        <PrimaryBtn onClick={() => navigate("/store")}>{t("Unlock")}</PrimaryBtn>
        <SecondaryBtn onClick={closeModal}>{t("NotNow")}</SecondaryBtn>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = tw.div` py-8 flex flex-col items-center justify-between`;
const Title = tw.h2`text-xl text-center font-bold text-gray-700  `;
const PrimaryBtn = tw.button`text-lg px-4 py-2 rounded-full font-medium text-white bg-primary-color block mt-8 w-full`;
const SecondaryBtn = tw.button` block text-gray-500 mt-3`;

export default ZeroModal;
