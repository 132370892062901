import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import ZeroModal from "../Components/ZeroModal";
import { useSelector } from "react-redux";
//import { t } from "i18next";
import { useTranslation } from "react-i18next";


const Winners = () => {
  const { t, i18n } = useTranslation();
  const data = [
    {
      id: 1,
      title: t("WINNERS"),
      img: Images.Winners,
      desc: t("lucky_winner"),
    },
    {
      id: 2,
      title: t("Grand_Prize"),
      desc: t("no_winner"),
    },
    {
      id: 3,
      title: t("m_prize"),
      desc: t("no_winner"),
    },
    {
      id: 4,
      title: t("prize"),
      desc: t("no_winner"),
    },
  ];

  return (
    <>
      <Navbar />

      <Wrapper>
        <Container>
          {/* <Title>How it works</Title> */}
          <CardWrapper>
            {data.map((singleData) => (
              <SingleCard key={singleData.id} {...singleData} />
            ))}
          </CardWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const SingleCard = ({ id, title, img, desc }) => {
  return (
    <SingleCardComp>
      {img && (
        <img src={img} alt="" className="w-24 h-24  object-contain mx-auto" />
      )}
      <h3 className="text-xl font-medium text-white mb-2">{title}</h3>
      <p className="text-sm text-gray-200">{desc}</p>
    </SingleCardComp>
  );
};

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen `;
const Container = tw.div`Container   text-white justify-center items-center pt-14 md:pt-20 text-center  `;

const SingleCardComp = tw.div`w-full border border-gray-400 rounded-md shadow-md p-5 py-8`;

const CardWrapper = tw.div` flex flex-col space-y-10  max-w-lg mx-auto`;

export default Winners;
