import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import Images from "../Images";

const GameplayNavbar = () => {
  const user = useSelector((state) => state.UserReducer);

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <Logo src={Images.LogoWhite} alt="" />
        </Link>

        <UserDetails>
          <div>
            <UserName>{user?.userName}</UserName>
            <Points>
              <b>{user?.points}</b> points
            </Points>
          </div>

          <User>
            <img
              src={Images.User}
              alt=""
              className="w-full h-full object-cover"
            />
          </User>
        </UserDetails>
      </Container>
    </Wrapper>
  );
};

const Wrapper = tw.nav` shadow-md border-b border-gray-700 h-16 flex items-center z-20 bg-primary-color `;
const Container = tw.div`Container flex items-center justify-between`;
const Logo = tw.img`w-20`;
const UserDetails = tw.div` flex items-start space-x-2 `;

const UserName = tw.h2`font-bold text-white text-sm`;
const Points = tw.p`text-green-500 text-xs `;

const User = tw.div`w-8 h-8 rounded-full object-cover overflow-hidden`;

export default GameplayNavbar;
