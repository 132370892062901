import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import ZeroModal from "../Components/ZeroModal";
import { useSelector } from "react-redux";
import { AiFillStar, AiFillTrophy } from "react-icons/ai";
import { MdGames, MdOutlineLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const goToGamePlayPage = () => navigate("/game-play");

  const user = useSelector((state) => state.UserReducer);

  const playGameHandler = () => {
    if (Number(user?.unlockedGames) > 0) {
      goToGamePlayPage();
    } else {
      openModal();
    }
  };

  return (
    <>
      <Navbar />
      <ZeroModal closeModal={closeModal} isModalOpen={isModalOpen} />
      <Wrapper>
        <InfoBox>
          <SingleInfo
            icon={<AiFillStar className="w-5 h-5 text-white" />}
            name={t("points")}
            value={user.points}
            url={"/"}
            navigate={navigate}
          />

          <VerticalBorder />

          <SingleInfo
            icon={<MdGames className="w-5 h-5 text-white" />}
            name={t("g_play")}
            value={user.playedGames}
            navigate={navigate}
            url={"/"}
          />

          <VerticalBorder />

          <SingleInfo
            icon={<AiFillTrophy className="w-5 h-5 text-white" />}
            name={t("rank")}
            navigate={navigate}
            value={user.rank}
            url={t("leader-board")}
          />
        </InfoBox>

        <Container>
          <Title>{t("home_part")}</Title>

          <Btn $outline onClick={playGameHandler}>
            {t("unlock_game")} {user?.unlockedGames}
          </Btn>

          <Btn onClick={playGameHandler}>{t("p_game")}</Btn>

          <Desc>
            {t("answer_correct")}
          </Desc>
		{/*
          <PrizeWrapper>
            <Prize>
              <img src={Images.MonthlyPrize} alt="" className="w-36" />
              <p className="text-primary-color text-xs font-medium mb-1">
                {t("monthly")}
              </p>
            </Prize>
            <Prize>
              <img src={Images.GrandPrize} alt="" className="w-36" />
              <p className="text-primary-color text-xs font-medium mb-1">
                {t("grand")}
              </p>
            </Prize>
          </PrizeWrapper>
		*/}
        </Container>
      </Wrapper>
    </>
  );
};

const SingleInfo = ({ icon, name, value, url, navigate }) => (
  <div className="flex flex-col items-center" onClick={() => navigate(url)}>
    {icon}
    <p
      className="text-xs font-medium text-gray-100 uppercase text-center mt-1 "
      style={{ fontSize: 10 }}
    >
      {name}
    </p>
    <p className="font-bold text-whte text-base text-white  text-center leading-7">
      {value}
    </p>
  </div>
);

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen `;
const Container = tw.div`Container   text-white justify-center items-center pt-4 md:pt-8 text-center  `;

const Title = tw.h1`font-bold text-2xl`;
const MainTitle = tw.h1`font-medium text-xl md:text-3xl z-10 `;
const Desc = tw.p`text-gray-200 text-lg z-10 sm:w-8/12 md:w-6/12 mx-auto mt-10`;

const PrizeWrapper = tw.div`flex my-10  justify-center items-center space-x-10`;

const Prize = tw.div`w-28 md:w-36 bg-white rounded-md overflow-hidden`;

const Btn = tw.button`
${(p) =>
  p.$outline
    ? "border border-white text-white"
    : "bg-gradient-to-r from-light-blue to-dark-blue  text-white"}
text-lg md:text-xl  flex items-center text-center justify-center rounded-full space-x-5 w-10/12 max-w-sm mx-auto my-7 md:my-9 h-12 md:h-16 font-bold shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105`;

const VerticalBorder = tw.div`h-8 w-0.5 bg-gray-300 rounded-md`;

const InfoBox = tw.div`w-full max-w-md mx-auto bg-gradient-to-r from-light-blue to-dark-blue shadow-lg   mb-8  sm:rounded-md px-8 py-2 flex justify-between md:justify-around items-center`;

export default Home;
