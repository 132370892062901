import React, { useState } from "react";
import GameplayNavbar from "../Components/GameplayNavbar";
import tw from "tailwind-styled-components";
import Images from "../Images";

const BonusGame = () => {
  const [showGame, setShowGame] = useState(false);
  const playGameHandler = () => setShowGame(true);
  return (
    <Wrapper>
      <GameplayNavbar />
      {showGame && (
        <iframe
          src="https://games.cdn.famobi.com/html5games/0/3d-free-kick/v080/?fg_domain=play.famobi.com&fg_aid=A1000-100&fg_uid=2ee096ab-4cd7-4f9a-baa9-f58a54413c47&fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&fg_beat=586&original_ref=https%3A%2F%2Fgames.cdn.famobi.com%2F"
          title="Football"
          className="w-full h-screen"
        />
      )}

      {!showGame && (
        <Container>
          <Title>Double or lose!</Title>
          <Desc>
            Score the penalty shoot and double your points!
            <br /> Miss it and lose half of them!
          </Desc>

          <ImageWrapper>
            <img
              src={Images.BonusImg}
              alt=""
              className="w-full mx-auto h-48  object-cover rounded-md  shadow-md"
            />

            <Btn onClick={playGameHandler}>Play Now</Btn>
          </ImageWrapper>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = tw.section`w-full bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen overflow-hidden`;
const Container = tw.div`Container flex flex-col pt-10`;
const Title = tw.h1`text-2xl md:text-3xl text-white font-bold text-center`;
const Desc = tw.h1`text-sm text-gray-200  text-center mt-3 md:text-base `;
const ImageWrapper = tw.div` w-full p-6  md:p-10  bg-primary-color rounded-lg border border-gray-500 mt-8 max-w-lg mx-auto`;
const Btn = tw.button`w-full py-2  bg-gradient-to-r from-light-blue to-dark-blue text-white rounded-full text-lg font-bold text-center mx-auto mt-6  md:mt-10 max-w-lg`;

export default BonusGame;
