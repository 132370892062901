import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../Config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";

const LeaderBoard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.UserReducer);
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  // Leader Board api
  const leaderboardFunction = (body) => {
    return axios.post(Config.baseUrl + "leaderboard", body);
  };

  const leaderboardSuccess = (resp) => {
    // console.log(resp.data);
    if (resp.data.status === "OK") {
      setData(resp.data.list);
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: leaderboardLoading, mutate: leaderboardMutate } =
    useMutation(leaderboardFunction, {
      onSuccess: leaderboardSuccess,
    });
  // end of Leader Board api

  useEffect(() => {
    const body = new FormData();
    body.append("mobile", user.mobile);
    leaderboardMutate(body);
  }, []);

  return (
    <>
      <Navbar />
      <Wrapper>
        <Title>{t("leader_board")}</Title>

        {leaderboardLoading && <LoadingComp />}

        <Container>
          <Heading t={t}/>
          {data.map((listData, index) => (
            <SingleUser
              key={listData.id}
              index={index + 1}
              {...listData}
              user={user}
            />
          ))}
        </Container>
      </Wrapper>
    </>
  );
};

const LoadingComp = () => {
  return (
    <LoadingWrapper>
      <ReactLoading type={"spin"} color={"#f47920"} height={50} width={50} />
    </LoadingWrapper>
  );
};

const Heading = ({t}) => (
  <div
    className={`flex items-center text-left py-3.5 px-3  rounded-md  bg-gradient-to-r from-light-blue to-dark-blue text-white font-bold`}
  >
    <p className="w-16 pl-2 text-left ">{t("srno")}</p>
    <p className="text-sm   ">{t("name")}</p>
    <p className="ml-auto w-20    ">{t("points")}</p>
  </div>
);
const SingleUser = ({ id, score, index, user }) => (
  <div
    className={`flex items-center text-left py-3.5 px-3 rounded-md text-white`}
    style={{ background: "rgba(255,255,255,0.1)" }}
  >
    <p className="w-16 pl-2 text-left ">{index}</p>
    <p className="text-sm   ">
      {id} {id === user.userName && " (You)"}
    </p>
    <p className="ml-auto w-20 font-bold   ">{score}</p>
  </div>
);

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen pt-8 `;
const Container = tw.div`Container   text-white flex flex-col space-y-5 pt-4 md:pt-8 text-center  sm:max-w-lg mx-auto `;

const Title = tw.h1`font-bold text-2xl text-white text-center mb-5`;

const LoadingWrapper = tw.div`fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-half-black`;

export default LeaderBoard;
