import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";

const SingleDuration = ({ result, light }) => {
  const [barColor, setBarColor] = useState("bg-gray-200");

  useEffect(() => {
    if (result === true) {
      setBarColor("bg-green-500");
    } else if (result === false) {
      setBarColor("bg-red-500");
    } else {
      setBarColor("bg-gray-200");
    }
  }, [result]);
  return (
    <Wrapper $light={light}>
      <p className="text-gray-400 text-xs sm:text-sm ">
        <b>20</b> points
      </p>
      <Bar $barColor={barColor} />
    </Wrapper>
  );
};

const Wrapper = tw.div`
${(p) =>
  p.$light ? "bg-gray-100 border-gray-200" : "bg-primary-color border-gray-600"}
 p-2 border `;

const Bar = tw.div`
${(p) => p.$barColor}
w-full h-1  mt-1.5 rounded-full`;

export default SingleDuration;
