import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../Config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";

const Plans = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.UserReducer);

    // plan api
    const plansFunction = (body) => {
      return axios.post(Config.baseUrl + "plan", body);
    };
  
    const plansSuccess = (resp) => {
      if (resp.data.status === "OK") {
        navigate("/");
      } else {
        toast.error(resp.data.msg || "An Error Occured");
      }
    };
  
    const { isLoading: registerLoading, mutate: plansMutate } = useMutation(
      plansFunction,
      {
        onSuccess: plansSuccess,
      }
    );
    // end of plan api


    const submitHandler = (plan) => {
        //e.preventDefault();
        const body = new FormData();
        body.append("mobile", user.mobile);
        
        if (plan == 15)
          body.append("plan", 7);
        else if (plan == 30)
          body.append("plan", 30);
        else
        body.append("plan", 1);
        plansMutate(body);
      }

  return (
    <>
      <Navbar />
      <Wrapper>
        <Title>{t("plan")}</Title>
        <SubTitle>
          {t("select_plan")} <br />
          {t("collect_prizes")}
        </SubTitle>

        <Container>
          <SingleUser gamesCount={t("Daily")} price={5} submitHandler={submitHandler}/>
          <SingleUser gamesCount={t("Weekly")} price={15} submitHandler={submitHandler}/>
          <SingleUser gamesCount={t("monthly")} price={30} submitHandler={submitHandler}/>
        </Container>
      </Wrapper>
    </>
  );
};

const LoadingComp = () => {
  return (
    <LoadingWrapper>
      <ReactLoading type={"spin"} color={"#f47920"} height={50} width={50} />
    </LoadingWrapper>
  );
};

const SingleUser = ({ gamesCount, price, submitHandler }) => (
  <div onClick={() => submitHandler(`${price}`)}
    className={`flex items-center justify-between text-left py-3.5 px-4 rounded-md text-white font-bold`}
    style={{ background: "rgba(255,255,255,0.1)" }}
  >
    <p className="text-sm  uppercase  ">{gamesCount} </p>
    <button className="bg-gradient-to-r from-light-blue to-dark-blue text-white py-2 px-4 rounded-full uppercase shadow-md">
      {price} AFN
    </button>
  </div>
);

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen pt-8 `;
const Container = tw.div`Container   text-white flex flex-col space-y-5 pt-4 md:pt-8 text-center  sm:max-w-lg mx-auto `;

const Title = tw.h1`font-bold text-2xl text-white text-center mb-5`;

const SubTitle = tw.h1`text-sm text-white text-center mb-5`;

const LoadingWrapper = tw.div`fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-half-black`;

export default Plans;
