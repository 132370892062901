const Images = {
  Overlay: "./images/overlay.svg",
  GrandPrize: "./images/card.jpg",
  MonthlyPrize: "./images/topup.png",
  ZeroBal: "./images/zero_ballance.png",
  Landing: "./images/landing_image.png",
  LandingPrizes: "./images/landing_prizes.png",
  Logo: "./images/logo.svg",
  LogoWhite: "./images/logo_white.svg",
  User: "./images/user.png",
  HomeBg: "./images/bg.jpg",
  Keyboard: "./images/keyboard.jpg",
  Keyboard: "./images/keyboard.jpg",
  BonusImg: "./images/game_img.jpg",
  Emoji: "./images/emoji.png",
  LoginBg: "./images/login_bg.webp",
  Register: "./images/Register.svg",
  Play: "./images/Play.png",
  Collect: "./images/Collect.png",
  Prizes: "./images/Prizes.png",
  Winners: "./images/WINNERS.png",
};

export default Images;
