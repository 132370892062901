import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { MdOutlineLanguage } from "react-icons/md";
import { BsBell } from "react-icons/bs";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_USER } from "../Redux/actions";
import UnsubscribeModal from "./UnsubscribeModal";
import Config from "../Config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  const toggleMenuHandler = () => setIsMenuOpen((prev) => !prev);
  const [showBookingSubLinks, setShowBookingSubLinks] = useState(false);

  const languageChangeHandler = (e) => {
    //setActiveLang(value);
    i18n.changeLanguage(e);
    console.log(e);
  };

  const logout = () => {
    dispatch(DELETE_USER());
    const body = new FormData();
    body.append("mobile", userMobile);
    unsubscribeMutate(body);
  };

  const userMobile = useSelector((state) => state.UserReducer.mobile);

  const openShowUnsubscribeModal = () => {
    setShowUnsubscribeModal(true);
    setIsMenuOpen(false);
  };

  // Unsubscribe api
  const unsubscribeFunction = (body) => {
    return axios.post(Config.baseUrl + "unsubscribe", body);
  };

  const unsubscribeSuccess = (resp) => {
    // console.log("success", resp.data);

    if (resp.data.status === "OK") {
      toast.success(resp.data.msg || "Success");
      navigate("/");
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: unsubscribeLoading, mutate: unsubscribeMutate } =
    useMutation(unsubscribeFunction, {
      onSuccess: unsubscribeSuccess,
    });
  // end of Unsubscribe api

  return (
    <>
      <Menu $isMenuOpen={isMenuOpen}>
        <Link to="/">
          <MenuItems>{t("home")}</MenuItems>
        </Link>

        <Link to="/how-it-works">
          <MenuItems>{t("how_work")}</MenuItems>
        </Link>
        
        {userMobile && (
          <>
          <Link to="/winners">
          <MenuItems>{t("winner")}</MenuItems>
        </Link>
       

        <Link to="/leader-board">
          <MenuItems>{t("leaderboard")}</MenuItems>
        </Link>

        <Link to="/store">
          <MenuItems>{t("store")}</MenuItems>
        </Link>
          <MenuItems onClick={openShowUnsubscribeModal}>{t("Unsubscribe")}</MenuItems>
          </>
        )}
        {userMobile && showUnsubscribeModal && (
          <UnsubscribeModal
            logout={logout}
            isModalOpen={showUnsubscribeModal}
            closeModal={() => setShowUnsubscribeModal(false)}
            unsubscribeLoading={unsubscribeLoading}
          />
        )}
      </Menu>

      <Wrapper>
        <Container>
          <div
            className="w-7 h-7 z-40 cursor-pointer"
            onClick={toggleMenuHandler}
          >
            <Bar className={`${isMenuOpen ? "rotate-45 translate-y-2" : ""}`} />
            <Bar className={`${isMenuOpen ? "hidden" : ""}`} />
            <Bar className={`${isMenuOpen ? "-rotate-45  " : ""}`} />
          </div>

          {userMobile &&
          <Link to="/" className="z-40">
            <Logo src={Images.LogoWhite} alt="" />
          </Link>
          }
          {/* <Icons>
            <MdOutlineLanguage className="text-gray-300 w-7 h-7" 
            
            />
          </Icons> */}

          <NavLink
        className="relative sm:hover:bg-transparent sm:px-0"
        onMouseEnter={() => setShowBookingSubLinks(true)}
        onMouseLeave={() => setShowBookingSubLinks(false)}
        onClick={() => setShowBookingSubLinks(!showBookingSubLinks)}
      >
        <Icons>
            <MdOutlineLanguage className="text-gray-300 w-7 h-7" 
            
            />
          </Icons>

          {showBookingSubLinks && (
          <SubLinksWrapper>
            <p
              onClick={()=>languageChangeHandler('english')}
              className="text-white hover:font-bold px-2 py-2"
            >
              {t('English')}
            </p>
            <Link
              onClick={()=>languageChangeHandler('dari')}
              className="text-white hover:font-bold px-2 py-2"
            >
              {t('Dari')}
            </Link>
            <Link
             onClick={()=>languageChangeHandler('pashto')}
              className="text-white hover:font-bold px-2 py-2"
            >
              {t('Pashto')}
            </Link>
          </SubLinksWrapper>
        )}

          </NavLink>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = tw.nav` shadow-md border-b border-gray-700 h-16 flex items-center z-20 bg-primary-color `;
const Container = tw.div`Container flex items-center justify-between relative `;
const Logo = tw.img`w-20`;
const Icons = tw.div`flex items-center space-x-4`;
const Bar = tw.div`h-0.5 w-6 bg-gray-300 my-1.5 transition transform duration-200`;
const Menu = tw.div`
${(p) =>
  p.$isMenuOpen
    ? "translate-x-0"
    : "-translate-x-full opacity-0 pointer-events-none"} min-h-screen w-full transition duration-200 fixed top-0 right-0 left-0 flex flex-col justify-center items-center bg-primary-color overflow-hidden z-10`;
const MenuItems = tw.p`font-bold  my-1 text-xl cursor-pointer text-white w-40 h-14 flex items-center justify-center rounded-md hover:bg-white hover:text-primary-color z-10`;

const NavLink = tw.li` 
${(p) => (p.$active ? "md:bg-gray-100 text-gray-900" : "")}
text-sm text-gray-500  hover:text-gray-900 relative flex space-x-1.5 items-center cursor-pointer py-3 h-9    whitespace-nowrap md:w-auto md:h-full ml-1 `;

const SubLinksWrapper = tw.div`flex flex-col absolute bottom-0 bg-gradient-to-r from-light-blue to-dark-blue shadow-lg p-2 py-3 text-sm  rounded-md  shadow-md z-10 transform  translate-y-full  -translate-x-4`;

export default Navbar;
