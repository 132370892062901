import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import GameplayNavbar from "../Components/GameplayNavbar";
import SingleDuration from "../Components/SingleDuration";
import Images from "../Images";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Config from "../Config";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import PointsModal from "../Components/PointsModal";
import { UPDATE_POINTS, UPDATE_UNLOCKEDGAMES } from "../Redux/actions";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

const Gameplay = () => {
  const dispatch = useDispatch();
  const [isInitialQuesFetched, setIsInitialQuesFetched] = useState(false);
  const [activeQues, setActiveQues] = useState({});
  const [userSelectedOption, setUserSelectedOption] = useState(null);
  const [resultArr, setResultArr] = useState([]);
  const [questionLimit, setQuestionLimit] = useState(4);
  const [totalPoints, setTotalPoints] = useState(0);
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [countdownKey, setCountdownKey] = useState(1);

  
  const { t, i18n } = useTranslation();
  const userMobile = useSelector((state) => state.UserReducer.mobile);
  const navigate = useNavigate();
  const goToBonusGame = () => navigate("/bonus-game");

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [i18n]);
  const [activeLang, setActiveLang] = useState(i18n.language || "");
  // useEffect(() => {
  //   // setBarLevel(prev);
  // }, [barLevel]);

  // Ques api
  const quesFunction = (body) => {
    return axios.post(Config.baseUrl + "getquestion", body);
  };

  const quesSuccess = (resp) => {
    // console.log("success", resp.data);
    if (resp.data.status === "OK") {
      // navigate("/");

      setActiveQues(resp.data.question);
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: quesLoading, mutate: quesMutate } = useMutation(
    quesFunction,
    {
      onSuccess: quesSuccess,
    }
  );
  // end of Ques api

  // Submit Ques api
  const submitQuesFunction = (body) => {
    return axios.post(Config.baseUrl + "submitresult", body);
  };

  const submitQuesSuccess = (resp) => {
    if (resp.data.status === "OK") {
      if (questionLimit === 1) {
        console.log("UPDATE_UNLOCKEDGAMES - " + resp.data.unlockgame);
        dispatch(UPDATE_UNLOCKEDGAMES(resp.data.unlockgame));
        setShowPointsModal(true);
      } else {
        console.log(resp.data.question);
        setActiveQues(resp.data.question);
      }
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: submitQuesLoading, mutate: submitQuesMutate } =
    useMutation(submitQuesFunction, {
      onSuccess: submitQuesSuccess,
    });
  // end of Submit Ques api

  useEffect(() => {
    if (!isInitialQuesFetched) {
      setIsInitialQuesFetched(true);
      const body = new FormData();
      body.append("mobile", userMobile);
      body.append("language", activeLang);
      quesMutate(body);
    }
  }, [isInitialQuesFetched]);
  const selectAnswerHandler = (selectedOption, optionNo) => {
    setUserSelectedOption(selectedOption);
    setQuestionLimit((prev) => --prev);
    console.log(optionNo);
    const isCorrect = optionNo === activeQues.answer ? true : false;
    setResultArr((prev) => [...prev, isCorrect]);

    if (isCorrect) {
      setTotalPoints((prev) => prev + 20);
      dispatch(UPDATE_POINTS(20));
    }

    const body = new FormData();
    body.append("mobile", userMobile);
    body.append("isCorrect", isCorrect ? "Y" : "N");
    body.append("points", activeQues.points);
    body.append("questionId", activeQues.id);
    body.append("language", activeLang);
    if (questionLimit == 1)
    {
      body.append("isEnd", "Y");
    }
    else {
      body.append("isEnd", "N");
    }
    body.append("campaign", activeQues.campaign);
    setCountdownKey((prev) => ++prev);
    submitQuesMutate(body);
    setUserSelectedOption('');
  };

  //console.log({countdownKey})


  const timeOutHandler = (props) => {
    if(countdownKey > 4) return;
    if(countdownKey <= 3){
      //console.log("time is up now", props);
      setCountdownKey((prev) => ++prev);
      
    }
    
    setUserSelectedOption("na");
    setQuestionLimit((prev) => --prev);
    setResultArr((prev) => [...prev, false]);
    
    

    const body = new FormData();
    body.append("mobile", userMobile);
    body.append("isCorrect", "N");
    body.append("points", activeQues.points);
    body.append("questionId", activeQues.id);
    body.append("campaign", activeQues.campaign);
    body.append("language", activeLang);
    if (questionLimit == 1)
    {
      body.append("isEnd", "Y");
    }
    else {
      body.append("isEnd", "N");
    }
    submitQuesMutate(body);
  };

  return (
    <Wrapper>
      <GameplayNavbar />

      {showPointsModal && (
        <PointsModal
          isModalOpen={showPointsModal}
          closeModal={() => setShowPointsModal(false)}
          resultArr={resultArr}
          goToBonusGame={goToBonusGame}
          totalPoints={totalPoints}
        />
      )}

      {(submitQuesLoading || quesLoading) && <LoadingComp />}

      <Container>
        <TimeDuration>
          <SingleDuration result={resultArr?.[0]} />
          <SingleDuration result={resultArr?.[1]} />
          <SingleDuration result={resultArr?.[2]} />
          <SingleDuration result={resultArr?.[3]} />
        </TimeDuration>

        <MainBar timeOutHandler={timeOutHandler} countdownKey={countdownKey} />

        <Ques>{activeQues.question}</Ques>

        {activeQues.type == "2" && (
          <ImageWrapper>
            <img
              src={activeQues.imagePath}
              alt=""
              className="w-full h-full object-contain"
            />
          </ImageWrapper>
        )}

        {activeQues.question && (
          <Options>
            <Option
              key={activeQues.o1}
              $selected={userSelectedOption === activeQues.o1}
              onClick={() => selectAnswerHandler(activeQues.o1, "o1")}
            >
              {activeQues.o1}
            </Option>
            <Option
              key={activeQues.o2}
              $selected={userSelectedOption === activeQues.o2}
              onClick={() => selectAnswerHandler(activeQues.o2, "o2")}
            >
              {activeQues.o2}
            </Option>
            <Option
              key={activeQues.o3}
              $selected={userSelectedOption === activeQues.o3}
              onClick={() => selectAnswerHandler(activeQues.o3, "o3")}
            >
              {activeQues.o3}
            </Option>
            <Option
              key={activeQues.o4}
              $selected={userSelectedOption === activeQues.o4}
              onClick={() => selectAnswerHandler(activeQues.o4, "o4")}
            >
              {activeQues.o4}
            </Option>
          </Options>
        )}
      </Container>
    </Wrapper>
  );
};

const MainBar = ({ timeOutHandler, countdownKey }) => {
  return (
    <>
      <Countdown
        // date={Date.now() + 15000}
        date={Date.now() + 1115000}
        key={countdownKey}
        intervalDelay={0}
        precision={3}
        onComplete={timeOutHandler}
        renderer={(props) => {
          const percentage = Math.round((props.total / 15000) * 100);
          return (
            <ProgressBar
              completed={percentage}
              transitionDuration="0.2s"
              className="w-full  bg-gray-500  my-8 rounded-full overflow-hidden "
              barContainerClassName="h-2"
              isLabelVisible={false}
              bgColor="#f47920"
            />
          );
        }}
      />
    </>
  );
};

const LoadingComp = () => {
  return (
    <LoadingWrapper>
      <ReactLoading type={"spin"} color={"#f47920"} height={50} width={50} />
    </LoadingWrapper>
  );
};

const Wrapper = tw.section` bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen  overflow-hidden `;

const Container = tw.div`Container   text-white justify-center items-center  text-center sm:max-w-lg mx-auto`;

const TimeDuration = tw.div` flex items-center justify-center `;

const Ques = tw.h1`font-medium text-xl `;

const Options = tw.div`flex flex-col space-y-5 mt-8 pb-8`;

const Option = tw.div`
${(p) =>
  p.$selected
    ? "text-secondary-color bg-white font-bold"
    : "text-white bg-primary-color"}
w-full  border border-gray-500 rounded-md text-center  text-sm py-4 hover:bg-white hover:text-secondary-color hover:font-bold cursor-pointer transition duration-200`;

const ImageWrapper = tw.div`max-w-sm p-2 mx-auto bg-primary-color rounded-lg border border-gray-500 mt-5 h-56 `;

const LoadingWrapper = tw.div`fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-half-black`;

export default Gameplay;
