export const ADD_USER = (body) => ({
  type: "ADD_USER",
  payload: body,
});

export const DELETE_USER = (body) => ({
  type: "DELETE_USER",
  payload: body,
});

export const UPDATE_POINTS = (body) => ({
  type: "UPDATE_POINTS",
  payload: body,
});

export const UPDATE_UNLOCKEDGAMES = (body) => ({
  type: "UPDATE_UNLOCKEDGAMES",
  payload: body,
});
