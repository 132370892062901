import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import NavbarLogin from "../Components/NavbarLogin";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useNavigate } from "react-router-dom";
import ZeroModal from "../Components/ZeroModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t, i18n } = useTranslation();

  const userMobile = useSelector((state) => state.UserReducer.mobile);

  const data = [
    {
      id: 1,
      title: t("Register"),
      img: Images.Register,
      desc: t("my_register"),
    },
    {
      id: 2,
      title: t("collect_point"),
      img: Images.Play,
      desc: t("challenge"),
    },
    {
      id: 3,
      title: t("add_game"),
      img: Images.Collect,
      desc: t("score_p"),
    },
    {
      id: 4,
      title: t("WinPrizes"),
      img: Images.Prizes,
      desc: t("collect_m_p"),
    },
  ];

  return (
    <>
      <Navbar />

      <Wrapper>
        <Container>
          <Title>{t("how_work")}</Title>
          <CardWrapper>
            {data.map((singleData) => (
              <SingleCard key={singleData.id} {...singleData} />
            ))}
          </CardWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const SingleCard = ({ id, title, img, desc }) => {
  return (
    <SingleCardComp>
      <img src={img} alt="" className="w-24 h-24 mb-5 object-contain mx-auto" />
      <h3 className="text-xl font-medium text-white mb-2">{title}</h3>
      <p className="text-sm text-gray-200">{desc}</p>
    </SingleCardComp>
  );
};

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative min-h-screen `;
const Container = tw.div`Container   text-white justify-center items-center pt-14 md:pt-20 text-center  `;

const Title = tw.h1`font-bold text-2xl`;
const MainTitle = tw.h1`font-medium text-xl md:text-3xl z-10 `;
const Desc = tw.p`text-gray-200 text-lg z-10 sm:w-8/12 md:w-6/12 mx-auto mt-5`;

const PrizeWrapper = tw.div`flex my-10  justify-center items-center space-x-10`;

const Prize = tw.div`w-28 md:w-36 bg-white rounded-md overflow-hidden`;

const Btn = tw.button`
${(p) =>
  p.$outline
    ? "border border-white text-white"
    : "bg-gradient-to-r from-light-blue to-dark-blue  text-white"}
text-lg md:text-xl  flex items-center text-center justify-center rounded-full space-x-5 w-10/12 max-w-sm mx-auto my-5 md:my-7 h-12 md:h-16 font-bold shadow-md hover:shadow-lg transition duration-200 transform hover:scale-105`;

const SingleCardComp = tw.div`w-full border border-gray-400 rounded-md shadow-md p-5`;

const CardWrapper = tw.div` flex flex-col space-y-10 py-10 max-w-lg mx-auto`;

export default HowItWorks;
